@media (min-width: 320px) {
  .font-size {
    font-size: 10px;
  }
  .smalldevice {
    height: 35rem;
    width: 35rem;
    gap: 0px;
  }
  .inner_sm_div {
    height: 6rem;
    width: 6rem;
  }
}
@media (min-width: 360px) {
  .font-size {
    font-size: 13px;
  }
  .smalldevice {
    height: 40rem;
    width: 40rem;
  }

  .inner_sm_div {
    height: 6.9rem;
    width: 6.9rem;
  }
}

@media (min-width: 640px) {
  .font-size {
    font-size: 18px;
  }
  .smalldevice {
    height: 55rem;
    width: 55rem;
  }

  .inner_sm_div {
    height: 6.9rem;
    width: 6.9rem;
  }
}

/* @media (max-width: 320px) {
  .font-size {
    font-size: 10px;
  }

  .boxSize {
    height: 6rem;
    width: 6rem;
  }

  .bottom_box {
    padding-top: 3rem;
  }

  .triangle {
    position: absolute;
    transform: rotate(0deg);
    width: 30px;
    height: 15px;
    margin-top: 3.5rem;
    margin-left: 3.5rem;
  }
} */

@media (min-width: 1663px) {
  .HoneyComb_div {
    margin-top: 3rem;
  }
}

/* @media screen and (min-width: 330px) and (max-width: 360px) {
  .triangle {
    position: absolute;
    transform: rotate(0deg);
    width: 30px;
    height: 15px;
    margin-top: 5rem;
    margin-left: 5rem;
  }
} */

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .outer_div {
    gap: 1.5rem;
  }

  .inner_div {
    gap: 1.5rem;
  }
  .sub_inner_div {
    height: 10rem;
    width: 10rem;
    font-size: medium;
  }
  .triangle {
    position: absolute;
    transform: rotate(0deg);
  }
  .outer_animateddiv_Box {
    margin-top: 1rem;
  }
}

/* ForMobile HoneyComeView */
@media screen and (min-width: 640px) and (max-width: 767px) {
  .Outer_sm_div {
    gap: 2rem;
  }
  .HoneyComb_div {
    margin-top: 8rem;
  }
  .semi_sm_div {
    gap: 2rem;
  }
  .inner_sm_div {
    height: 10rem;
    width: 10rem;
    border-radius: 1.5rem;
  }
  .triangle {
    position: absolute;
    transform: rotate(0deg);
    width: 50px;
    height: 25px;
    margin-top: 6rem;
    margin-left: 6rem;
  }
  .outer_animateddiv_Box {
    margin-top: 33rem;
  }

  .line::after {
    content: "";
    position: absolute;
    width: 2px; /* Adjust the thickness of the line as needed */
    height: calc(100% - 15.5px); /* Adjust the height of the line as needed */
    background-color: white; /* Example line color */
    top: -40px; /* Adjust the position of the line vertically as needed */
    left: 50%; /* Adjust the position of the line horizontally as needed */
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Outer_sm_div {
    gap: 2rem;
  }

  .semi_sm_div {
    gap: 2rem;
  }
  .inner_sm_div {
    height: 10rem;
    width: 10rem;
    border-radius: 1.3rem;
  }
  .triangle {
    position: absolute;
    transform: rotate(0deg);
    width: 50px;
    height: 25px;
    margin-top: 6rem;
    margin-left: 6rem;
  }
  .outer_animateddiv_Box {
    margin-top: 33rem;
  }

  .line::after {
    content: "";
    position: absolute;
    width: 2px; /* Adjust the thickness of the line as needed */
    height: calc(100% - 0px); /* Adjust the height of the line as needed */
    background-color: white; /* Example line color */
    top: -56px; /* Adjust the position of the line vertically as needed */
    left: 50%; /* Adjust the position of the line horizontally as needed */
  }
  .font-size {
    font-size: 18px;
  }
}
