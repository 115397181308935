/* .parent-div-god {
  display: flex;
  align-items: center;
}
.line-maker-god-one {
  display: block;
  width: 150px;
  background-color: aliceblue;
  height: 2px;
}
.line-middle {
  position: absolute;
  display: block;
  border-width: 2px;
  height: 562px;
  border-right: none;
  border-bottom: none;
  top: 23.4%;
}
@media (max-width: 1913px) {
  .line-middle {
    position: absolute;
    display: block;
    border-width: 2px;
    height: 593px;
    border-right: none;
    border-bottom: none;
    top: 21.9%;
  }
}
@media (max-width: 1881px) {
  .line-middle {
    position: absolute;
    display: block;
    border-width: 2px;
    height: 639px;
    border-right: none;
    border-bottom: none;
    top: 18.9%;
  }
} */

.horizentalline {
  position: absolute;
  left: 44.5%; /* Adjust the left position as needed */
  top: 22.9%; /* Center the rectangle vertically */
  transform: translateX(-50%) translateY(-50%); /* Center the rectangle horizontally and vertically */
  width: 2px; /* Adjust the width of the rectangle as needed */
  height: 54.3%; /* Adjust the height of the rectangle as needed */
  background-color: white; /* Adjust the background color as needed */
}

@media (max-width: 1920px) and (max-width: 2560px) {
  .horizentalline {
    position: absolute;
    left: 44.9%;
    top: 18.3%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: 63.3%;
    background-color: white;
  }
}
/* @media (max-width: 2427px) {
  .horizentalline {
    position: absolute;
    left: 44.5%;
    top: 21.5%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: 57%;
    background-color: white;
  }
}

@media (max-width: 2116px) {
  .horizentalline {
    position: absolute;
    left: 44.8%;
    top: 20%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: 60%;
    background-color: white;
  }
}

@media (max-width: 1920px) {
  .horizentalline {
    position: absolute;
    left: 44.85%;
    top: 18.3%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: 63.32%;
    background-color: white;
  }
}

@media (min-width: 1920px) {
  .horizentalline {
    position: absolute;
    left: 44.85%;
    top: 18.3%;
    transform: translateX(-50%) translateY(-50%);
    width: 2px;
    height: 63.32%;
    background-color: white;
  }
} */
