@tailwind base;
@tailwind components;
@tailwind utilities;

.markdown > * {
  all: revert;
}

/* text of 600 weight && fontsize of 25px font source Sans Pro */

.FW_600_FS_25px {
  @apply font-bold md:text-paragraphHomepage2xl text-orangePrimary;
}

.FW_300_FS_18px {
  @apply text-white text-center md:text-descriptionHeading2xl  md:leading-8 overflow-clip;
}

.btn-gradient-1 {
  background: /* Gradient in Hex */ linear-gradient(
        to right,
        #051c25 0%,
        34.285715222358704%,
        #051c25 68.57143044471741%,
        84.2857152223587%,
        #051c25 100%
      )
      padding-box,
    linear-gradient(to right, #f58220, #d0ba93) border-box;
  border: 8px solid transparent;
}

@keyframes colorchange {
  0% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #f58220, #d0ba93) border-box;
  }
  20% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #f38b2f, #d8bc8b) border-box;
  }
  40% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #f5923c, #ddbd84) border-box;
  }
  60% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #f09a50, #e2b76d) border-box;
  }
  80% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #e99e5d, #e9b65d) border-box;
  }
  100% {
    background: linear-gradient(
          to right,
          #051c25 0%,
          34.285715222358704%,
          #051c25 68.57143044471741%,
          84.2857152223587%,
          #051c25 100%
        )
        padding-box,
      linear-gradient(to right, #d0ba93, #f58220) border-box;
  }
}

.btn-gradient-1:hover {
  transition: 0.3s;
  transform: scale(1.15);
  rotate: 90deg;
  animation: colorchange 0.3s ease-in-out forwards;
}

.assistCard:hover {
  /* box-shadow: 0 0 16px #f58220; */
  box-shadow: 0 0 20px #18a5d8;
}

.triangle {
  position: absolute;
  rotate: -45deg;
  margin-top: 10rem;
  margin-left: 10rem;
}
.over_flow {
  -webkit-line-clamp: 11;
  white-space: nowrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.blur-small {
  filter: blur(2px);
  transition: 0.3s ease-in-out;
}

@media only screen and (max-width: 639px) {
  body::-webkit-scrollbar {
    display: none;
  }

  .btn-gradient-1 {
    border: 4px solid transparent;
  }
}

.trapezoid_left {
  border-bottom: 210px solid #f28916;
  border-left: 120px solid transparent;
  border-right: 120px solid transparent;
  height: 0;
  width: 370px;
}
.trapezoid_bt_left {
  border-bottom: 220px solid #f28916;
  border-left: 130px solid transparent;
  border-right: 130px solid transparent;
  height: 0;
  width: 370px;
}

/* Linear Gredian Background */

.linear_bg_Class {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(20, 104, 138, 0.18) 48%,
    rgba(20, 104, 138, 0.1) 100%
  );
}

.linear_secound {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(20, 104, 138, 0.1897934173669467) 48%,
    rgba(20, 104, 138, 0.17777) 100%
  );
}
