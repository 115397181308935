@media only screen and (max-width: 1024px) {
  .shadowed {
    filter: drop-shadow(0px 0px 10px #000000);
  }
  .blogDetailsParent span {
    font-size: 13px !important;
  }
  .blogDetailsParent strong {
    font-size: 16px !important;
  }
  .blogDetailsParent p {
    font-size: 13px !important;
  }
  .blogDetailsParent u {
    font-size: 13px !important;
  }
  .blogDetailsParent li {
    display: list-item !important;
    list-style-type: circle !important;
  }
  .blogDetailsParent td {
    font-size: 13px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blogDetailsParent table {
    width: 100% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .blogDetailsParent table {
    max-width: 100%;
    width: max-content !important;
    display: flex;
    flex-wrap: wrap;
  }

  .blogDetailsParent td {
    width: fit-content !important;
    flex-wrap: wrap;
  }
}

@media (min-width: 640px) {
  .shadowFont {
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  .requestADemoGradient {
    background: linear-gradient(
      180deg,
      rgba(1, 26, 40, 0.8) 36%,
      rgba(245, 130, 32, 0.56) 100%
    );
    box-shadow: 0px 4px 32px 5px #00000040;
  }
}

@media (max-width: 640px) {
  .ourTeamImageGradient {
    background: linear-gradient(180deg, #cdd3d6 0%, #fdfdfd 100%);
  }
}

html,
body {
  height: 100%;
  color: white;
  font-family: "Source Sans Pro", sans-serif;
  /* scrollbar-width: 5px;
  scrollbar-color: #747373 #afadad; */
}

a:active,
a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

body::-webkit-scrollbar-track {
  background-color: inherit;
}

::-webkit-scrollbar-corner {
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px;
  background: #afadad !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #747373;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5e5c5c;
}

::-webkit-scrollbar:hover {
  width: 10px;
  background: #dbdada !important;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}
.curvedDivBoxHover:hover {
  box-shadow: 0 0 16px #f47924;
  cursor: pointer;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.flyAnimation {
  animation: fade 0.2s ease-out;
}

.drop-shadow img {
  filter: drop-shadow(20px 20px 20px #666666);
  -webkit-filter: drop-shadow(20px 20px 20px #666666);
}

svg {
  width: 70px;
  height: 70px;
}

svg:hover {
  fill: red;
}

.mainPlastic {
  background: linear-gradient(
    180deg,
    rgba(20, 104, 138, 0.04) 5.21%,
    #10354b 58.85%
  );
}

.fadeContent {
  animation: fade 1.5s ease-in-out;
}

.no-hover-effect:hover {
  fill: white;
}

.roboto {
  font-family: "Roboto";
}

.source-sans {
  font-family: "Source Sans Pro", sans-serif;
  font-display: swap;
}

.buttonOrangeGlow:hover {
  box-shadow: 0 0 16px #f47924;
}

.buttonBlueGlow:hover,
.mainBlogCard:hover {
  box-shadow: 0 0 16px #18a5d8;
}

.bluePrimaryGradient {
  background: linear-gradient(
    180deg,
    rgba(20, 104, 138, 0.02) 5.21%,
    rgba(20, 104, 138, 0.5) 58.85%
  );
}

.blueSecondaryGradient {
  background: linear-gradient(180deg, #011a28 5.21%, #032131 58.85%);
}

.orangePrimaryGradient {
  background: linear-gradient(180deg, #f15c2d 0%, #f47924 100%);
}

.topButton {
  box-shadow: 0 0 24px #f47924;
}

.topButton:hover {
  box-shadow: 0 0 24px #ffffff;
}

.topButton:hover > .clickTopSymbol {
  fill: white;
}

.orangeSecondaryGradient {
  background: linear-gradient(135deg, #f47924 15.43%, #d0ba93 79.42%);
}

.border-gradient-orange {
  border-image-slice: 1;
  border-image-source: linear-gradient(to bottom, #fc581c, #f47924);
}

.landingCardButton:hover > .landingCardVector {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: rotate(-90deg);
  transition: transform 0.4s ease;
  color: #f47924;
}

.landingCardButton:hover {
  transition: transform 0.3s ease-in;
}

.subMenu:hover > .subMenuChev {
  transition: transform 0.4s ease;
  color: #f47924;
}

.mainLandingCard {
  background: rgba(217, 217, 217, 0.1);
}

.dropdown-content {
  left: 0 !important;
  right: 0 !important;
}

.shadowed {
  filter: drop-shadow(0px 0px 30px #000000);
}

.curvedDivIMG {
  background-image: url("Images/curvedDivIMG.png");
  background-size: cover;
}

.navbarProductContainer:hover > .navbarProductHeading {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition: transform 0.4s ease;
}

.growAnimationCurvedDiv:hover {
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.curvedDivBoxHover:hover {
  box-shadow: 0 0 16px #f47924;
  cursor: pointer;
}

.mainBlogCardButton:hover {
  color: #f47924;
  column-gap: 0.6rem;
  cursor: pointer;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transition: transform 0.2s ease-in-out;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* Default styles */
.requestADemoGradient {
  background: linear-gradient(
    90deg,
    #011a28 30%,
    rgba(30, 39, 39, 0.963793) 58%,
    rgba(245, 130, 32, 0.4) 100%
  );
}

.newLandingCardBG {
  background: linear-gradient(
    0deg,
    rgba(217, 217, 217, 0.7) 0%,
    rgba(115, 115, 115, 0.42) 100%
  );
  box-shadow: 3px 4px 5px 0px #00000047;
}

.popupTeamDetailsBG {
  background: linear-gradient(0deg, #d9d9d9 0%, #9a9a9a 100%);
  box-shadow: 3px 4px 5px 0px #00000047;
}

.timelineGradient {
  background: rgb(1, 26, 40);
  background: linear-gradient(90deg, #0c465e 0%, #011a28 100%);
}

.hexagonBG {
  background-image: url("Images/hexagon.svg");
  background-size: contain;
}

.aboutUsImageGradient {
  background: linear-gradient(
    90deg,
    rgba(1, 26, 40, 0.7) 0%,
    rgba(245, 130, 32, 0.35) 68.5%
  );
}

/* Styles for screen widths 640px and above */
@media (min-width: 640px) {
  .requestADemoGradient {
    background: linear-gradient(
      180deg,
      rgba(1, 26, 40, 0.8) 36%,
      rgba(245, 130, 32, 0.56) 100%
    );
    box-shadow: 0px 4px 32px 5px #00000040;
  }
}

.new-gradient {
  background: linear-gradient(
    180deg,
    rgba(20, 104, 138, 0.04) 5.21%,
    #14688a 58.85%
  );
  opacity: 10%;
}

.industryCardShadow {
  box-shadow: 1px 2px 40.2px 6px #00000040;
}

.blogDetailsParent * {
  font-size: 20px !important;
}
